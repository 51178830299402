import { useState, useEffect, useCallback } from 'react'

const useForm = () => {
	const [submitting, setSubmitting] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [values, setValues] = useState({})
	const [success, setSuccess] = useState(false)

	function encode(data) {
		return Object.keys(data)
			.map(
				(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
			)
			.join('&')
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setSubmitting(true)
		const data = new FormData(e.target)
		const newValues = {}
		for (var pair of data.entries()) {
			newValues[pair[0]] = pair[1]
		}
		setValues(newValues)
		console.log('hey', e.target.getAttribute('name'))
		setSubmitted(true)
	}

	const sendValues = useCallback(() => {
		/**
		 * Code for submitting form values
		 * via an external API goes here.
		 */
		// setTimeout(function() {
		//   console.log(values)
		//   setSuccess(true)
		//   setSubmitting(false)
		// }, 2000)
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				...values,
				'form-name': 'contact',
			}),
		})
			.then(() => {
				console.log('heyyyy')
				setSuccess(true)
				setSubmitting(false)
			})
			.catch((err) => {
				setSuccess(false)
				setSubmitting(false)
			})
	}, [values])

	useEffect(() => {
		if (submitted) {
			sendValues((err) => alert(err))
		}
	}, [submitted, sendValues])

	return {
		handleSubmit,
		submitting,
		submitted,
		success,
	}
}

export default useForm
