import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
	<>
		<Seo title='Contact' />
		<Divider />
		<Stack>
			<Main>
				<PageTitle
					header="Let's Connect"
					subheader='You can reach out via the contact form below. Tell us about your latest project or just drop a note to say ‘Hi’. Hey, there are no rules here….. Let’s Connect.'
				/>
				<Divider />
				<ContactForm />
			</Main>
			<Sidebar>
				<Commitment />
				<Divider />
				<ContactInfo />
			</Sidebar>
		</Stack>
	</>
)
